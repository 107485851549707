import { configureStore } from "@reduxjs/toolkit";

import sessionMiddleware from "./Data/middleware/sessionMiddleware";

import userReducer from "./Data/features/userSlice";

import authSlice from "./Data/features/authSlice";

import dashboardSlice from "./Data/features/dashboardSlice";

import { userApi } from "./Data/services/userApi";

import { userAuthApi } from "./Data/services/userAuthApi";

import { dashboardApi } from "./Data/services/dashboardApi";

import staticContentSlice from "./Data/features/staticContentSlice";

import cardTemplateSlice from "./Data/features/cardTemplateSlice";

import { staticApi } from "./Data/services/staticApi";

import { cardTemplateApi } from "./Data/services/cardTemplateApi";

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [staticApi.reducerPath]: staticApi.reducer,
    [cardTemplateApi.reducerPath]: cardTemplateApi.reducer,
    user: userReducer,
    userAuth: authSlice,
    dashboard: dashboardSlice,
    staticContent: staticContentSlice,
    cardTemplate: cardTemplateSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      userAuthApi.middleware,
      dashboardApi.middleware,
      staticApi.middleware,
      cardTemplateApi.middleware,
      sessionMiddleware,
    ]),
});

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:
