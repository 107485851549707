import { getToken } from "./localStorageService";
import { baseApi } from "./baseApi";

export const cardTemplateApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    getAllCardTemplate: builder.query({
      query: () => {
        return {
          url: "/admin/get-all-template",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    deleteCardTemplate: builder.mutation({
      query: (slug) => {
        return {
          url: `/admin/template-delete/${slug}`,
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const { useGetAllCardTemplateQuery, useDeleteCardTemplateMutation } =
  cardTemplateApi;
