import React, { useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import {
  useDeleteCardTemplateMutation,
  useGetAllCardTemplateQuery,
} from "../../Data/services/cardTemplateApi";
import styles from "./styles/card_template.module.scss";
import { Tooltip } from "react-bootstrap";

const CardTemplateManagement = ({ setPage }) => {
  const dispatch = useDispatch();

  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const [editModal, setEditModal] = useState(false);

  const { data, refetch, isLoading } = useGetAllCardTemplateQuery({
    // refetchOnMountOrArgChange: true,
  });

  const [deleteCardTemplate, { isLoading: deleteLoading }] =
    useDeleteCardTemplateMutation();

  var cardTemplates = useSelector((state) => state.cardTemplate.cardTemplate);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = async (slug) => {
    await deleteCardTemplate(slug);
    setDeleteModal(false);
    refetch();
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  function indication() {
    return isLoading ? "Record Not found" : "Loading...";
  }

  const sliceString = (string, length) => {
    if (!string) return "-";

    return string?.length > length ? (
      <div data-toggle="tooltip" data-placement="top" title={string}>
        {string?.slice(0, length) + "..."}
      </div>
    ) : (
      string
    );
  };

  const columns = [
    {
      dataField: "full_name",
      text: "Full Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => sliceString(row?.full_name, 20),
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => sliceString(row?.designation, 20),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => sliceString(row?.email, 20),
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => sliceString(row?.address, 20),
    },

    {
      dataField: "brand_name",
      text: "Brand Name",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => sliceString(row?.brand_name, 20),
    },
    {
      dataField: "contact",
      text: "Contact",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => sliceString(row?.contact, 20),
    },
    {
      dataField: "qr_code",
      text: "QR code",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        let url = process.env.REACT_APP_API_DOMAIN;
        url = url?.slice(0, url.lastIndexOf("/"));

        return (
          <div>
            <img
              src={row?.qr_code ? row?.qr_code : `${url}/user-placeholder.jpeg`}
              style={{ width: 50 }}
            />
          </div>
        );
      },
    },
    {
      dataField: "image_url",
      text: "Card Image",
      sort: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (cellContent, row) => {
        let url = process.env.REACT_APP_API_DOMAIN;
        url = url?.slice(0, url.lastIndexOf("/"));
        return (
          <div>
            <img
              src={
                row?.image_url ? row?.image_url : `${url}/user-placeholder.jpeg`
              }
              style={{ width: 50, height: 50 }}
            />
          </div>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              {/* <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 bg-success text-white`}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1  `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div> */}
              <div
                className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 text-white "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          loading={deleteLoading}
        />
      )}

      {editModal && (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          refetch={refetch}
        />
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Card Template Management"} classes="text-black" />
        </div>
      </div>

      <div className={styles.mainTable}>
        <Table
          data={cardTemplates || []}
          columns={columns}
          indication={indication}
        />
      </div>
    </div>
  );
};

export default CardTemplateManagement;
