import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import TextEditor from "../../Components/TextEditor/TextEditor";
import {
  useGetStaticContentQuery,
  useUpdateStaticContentMutation,
} from "../../Data/services/staticApi";
import styles from "./styles/StaticPage.module.scss";
import Toast from "../../Hooks/useToast";
import _ from "lodash";
import { useSelector } from "react-redux";
import ButtonLoading from "../../Components/Loaders/ButtonLoading";

export default function TermsAndConditions() {
  const [updateStaticContent, { isLoading }] = useUpdateStaticContentMutation();

  const { refetch } = useGetStaticContentQuery();

  const staticContent = useSelector(
    (state) => state.staticContent.record.PRIVACY
  );

  const [value, setValue] = useState(staticContent?.text || "");

  const [link, setLink] = useState(staticContent?.link || "");

  const handleLinkChange = (e) => setLink(e.target.value);

  const handleSaveText = (e) => setValue(e);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const body = {
        type: "PRIVACY",
        text: value,
        link: link,
      };

      const response = await updateStaticContent(body);
      if (response?.data?.code == 200) {
        const { message } = response?.data;
        Toast(message, "success");
        await refetch();
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col sm={10}>
            <SubTitle text="Privacy Policy" classes="mb-4 " />
          </Col>

          <Col sm={2}>
            <Button
              type="submit"
              classes={styles.cardBtn}
              text={isLoading ? <ButtonLoading /> : "Save"}
            />
          </Col>
        </Row>

        <Input
          name="link"
          type="text"
          value={link}
          placeholder="Write link here"
          onChange={handleLinkChange}
        />

        <TextEditor text={value} onChange={handleSaveText} />
      </Form>
    </div>
  );
}
