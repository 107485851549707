import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/StaticPage.module.scss";
import Toast from "../../Hooks/useToast";
import {
  useGetStaticContentQuery,
  useUpdateStaticContentMutation,
} from "../../Data/services/staticApi";
import _ from "lodash";
import ButtonLoading from "../../Components/Loaders/ButtonLoading";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const [updateStaticContent, { isLoading }] = useUpdateStaticContentMutation();

  const { refetch } = useGetStaticContentQuery();

  const staticContent = useSelector(
    (state) => state.staticContent.record.ADMINCONTACT
  );

  const [adminEmail, setAdminEmail] = useState("");

  const handleAdminEmail = (e) => {
    setAdminEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      const body = {
        type: "ADMINCONTACT",
        text: adminEmail,
      };

      const response = await updateStaticContent(body);
      if (response?.data?.code == 200) {
        const { message } = response?.data;
        Toast(message, "success");
        await refetch();
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className={styles.StaticPage}>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col sm={10}>
            <SubTitle text="Contact Us" classes="mb-4" />
          </Col>
          <Col sm={2}>
            <Button
              classes={styles.cardBtn}
              text={isLoading ? <ButtonLoading /> : "Save"}
              disabled={isLoading}
              type="submit"
            />
          </Col>
        </Row>

        <p>Set your email, App user will contact you on this email.</p>

        <Row>
          <Col md={12}>
            <p className="m-0 ">Email</p>
            <Input
              classes="mt-2 mb-3"
              type="email"
              placeholder="Enter Admin Email"
              value={adminEmail ? adminEmail : staticContent?.text}
              onChange={handleAdminEmail}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactUs;
