import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import { updateUsers } from "../../Data/features/userSlice";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../../Data/services/userApi";
import styles from "./styles/user_management.module.scss";

const UserManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();
  const [editedData, setEditedData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, refetch, isLoading } = useGetAllUsersQuery({
    // refetchOnMountOrArgChange: true,a
  });

  const [deleteUser, { isLoading: deleteLoading }] = useDeleteUserMutation();

  var userData = useSelector((state) => state.user.users);

  useEffect(() => {
    if (!!data?.data?.length) {
      // setRowData(data.data);
      dispatch(updateUsers(data.data));
    }
  }, [data]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = async (slug) => {
    await deleteUser(slug);
    setDeleteModal(false);
    refetch();
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  function indication() {
    return isLoading ? "Record Not found" : "Loading...";
  }

  const columns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status) {
          return <div className={styles.active}> Active </div>;
        } else {
          return <div className={styles.block}>Block</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 bg-success text-white`}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1  `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 text-white "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal && (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          loading={deleteLoading}
        />
      )}

      {editModal && (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          refetch={refetch}
        />
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Management"} classes="text-black" />
        </div>
      </div>

      <div className={styles.mainTable}>
        <Table data={userData || []} columns={columns} indication={indication} />
      </div>
    </div>
  );
};

export default UserManagementScreen;
