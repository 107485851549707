import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const userAuthApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: "/admin/login",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    forgotPassword: builder.mutation({
      query: (user) => {
        return {
          url: "/admin/forgot-password",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    changePassword: builder.mutation({
      query: (user) => {
        return {
          url: "/admin/change-password",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
  }),
});

export const {
  useLoginUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
} = userAuthApi;
