import { createSlice } from "@reduxjs/toolkit";
import { cardTemplateApi } from "../services/cardTemplateApi";

const initialState = {
  cardTemplate: [],
};

export const cardTemplateSlice = createSlice({
  name: "cardTemplateSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        cardTemplateApi.endpoints.getAllCardTemplate.matchFulfilled,
        (state, { payload }) => {
          state.cardTemplate = payload.data;
        }
      )
      .addMatcher(
        cardTemplateApi.endpoints.getAllCardTemplate.matchRejected,
        (state) => {
          state.cardTemplate = [];
        }
      );
  },
});

export const {} = cardTemplateSlice.actions;

export default cardTemplateSlice.reducer;
