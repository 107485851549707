import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const userApi = baseApi.injectEndpoints({
  reducerPath: "userApi",
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: (user) => {
        return {
          url: "add_user",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: (user) => {
        return {
          url: "/admin/update-user",
          method: "PATCH",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      // invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (slug) => {
        return {
          url: `/admin/delete-user/${slug}`,
          method: "DELETE",
          body: {},
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
    getAllUsers: builder.query({
      query: () => {
        return {
          url: "/admin/user-list",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
  }),
});

export const {
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
} = userApi;
