import { getToken } from "../../Data/services/localStorageService";
import { baseApi } from "./baseApi";

export const dashboardApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    dashboardApi: builder.query({
      query: (body) => {
        return {
          url: "/admin/dashboard",
          method: "GET",
          body,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
    }),
  }),
});

export const { useDashboardApiQuery } = dashboardApi;
