import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row, Spinner } from "react-bootstrap";

import { FaUsers } from "react-icons/fa";

import DashboardCard from "../../Components/Cards/DashboardCard";

import { SubTitle } from "../../Components/CustomComponents";

import { IoMdCard } from "react-icons/io";

import { FaRegCreditCard } from "react-icons/fa";

import { useSelector } from "react-redux";

import { useDashboardApiQuery } from "../../Data/services/dashboardApi";

export default function Dashboard({ setPage }) {
  const { data, isLoading } = useDashboardApiQuery();

  const dashboardRecord = useSelector((state) => state.dashboard.dashboard);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="text-black" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<FaUsers color="#934AFC" />}
            number={isLoading ? <Spinner /> : dashboardRecord?.user_counts}
            // dashboardRecord?.user_counts
            text={"Numbers of users"}
          />
        </Col>

        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<IoMdCard color="#934AFC" />}
            number={isLoading ? <Spinner /> : dashboardRecord?.template_count}
            text={"Numbers of cards"}
          />
        </Col>

        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#934AFC"}
            bg2={"#49A4FD"}
            icon={<FaRegCreditCard color="#934AFC" />}
            number={isLoading ? <Spinner /> : dashboardRecord?.popular_count}
            text={"Numbers of popular cards"}
          />
        </Col>
      </Row>
    </div>
  );
}
