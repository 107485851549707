import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import {
  Button,
  CustomInput,
  Description,
  Title,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";
import { useForgotPasswordMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import ButtonLoading from "../../Components/Loaders/ButtonLoading";
import { WEB_ROUTES } from "../../constants";

export default function ForgotPassword({ setPage }) {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleDataSubmit = async (record) => {
    try {
      const response = await forgotPassword(record);
      reset();
      if (response?.data?.code == 200) {
        const { message } = response?.data;
        Toast(message, "success");
        navigate(WEB_ROUTES.ACCOUNT);
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccountLayout
      hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      <Title text="Forgot Password" classes="mb-2 text-black" />
      <Description
        text="In order to reset your password you need to enter your registered email address."
        classes="text-center fs-7 text-black"
      />
      <Form onSubmit={handleSubmit(handleDataSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />
        <Button
          text={isLoading ? <ButtonLoading /> : "Continue"}
          classes="mt-3 mb-2"
          disabled={isLoading}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}
