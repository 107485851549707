import { createSlice } from "@reduxjs/toolkit";
import { staticApi } from "../services/staticApi";

const initialState = {
  record: {},
};

export const staticContentSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        staticApi.endpoints.getStaticContent.matchFulfilled,
        (state, { payload }) => {
          state.record = payload.data;
        }
      )
      .addMatcher(
        staticApi.endpoints.getStaticContent.matchRejected,
        (state) => {
          state.record = {};
        }
      );
  },
});

export const {} = staticContentSlice.actions;

export default staticContentSlice.reducer;
