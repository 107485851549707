import _ from "lodash";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants.js";
import { storeToken } from "../../Data/services/localStorageService.js";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";
import ButtonLoading from "../../Components/Loaders/ButtonLoading.jsx";

export default function Login({ setPage }) {
  const navigate = useNavigate();

  const { handleSubmit, control, register } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      device_type: "web",
      device_token: "1122",
    },
  });

  const [loginUser, { isLoading: loginLoading }] = useLoginUserMutation();

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleLogin = async (data) => {
    try {
      const result = await loginUser(data);
      if (result?.data?.code === 200) {
        const { message, data } = result?.data;
        storeToken(data?.api_token);
        Toast(message, "success");
        navigate(WEB_ROUTES.DASHBOARD);
      } else if (!_.isEmpty(result?.error)) {
        const { message } = result?.error?.data;
        Toast(message, "error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <AccountLayout>
      <SubTitle text="Admin Panel" classes="mb-2 text-black" />

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3 "
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text={loginLoading ? <ButtonLoading /> : "Login"}
          classes="mt-3 mb-2"
          type="submit"
          disabled={loginLoading}
        />
      </Form>
      <Description
        classes="ms-auto cursor-pointer text-black"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}
