import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import { WEB_ROUTES } from "../../constants.js";
import { useChangePasswordMutation } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import styles from "./styles/ChangePassword.module.scss";
import ButtonLoading from "../../Components/Loaders/ButtonLoading.jsx";

export default function ChangePassword() {
  const { handleSubmit, control, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const navigate = useNavigate();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleDataSubmission = async (record) => {
    try {
      let response = await changePassword({
        current_password: record?.currentPassword,
        new_password: record?.newPassword,
        confirm_password: record?.confirmPassword,
      });
      reset();
      if (response?.data?.code == 200) {
        const { message } = response?.data;
        Toast(message, "success");
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={styles.ChangePassword}>
      <SubTitle text="Change Password" />

      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />

      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1" />
          <Controller
            control={control}
            name="currentPassword"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1" />
          <Controller
            control={control}
            name="newPassword"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
                value={value}
              />
            )}
          />

          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4"
          />

          <div className={styles.saveButton}>
            <Button
              text={isLoading ? <ButtonLoading /> : "Change Password"}
              classes="fs-6 mb-2 mt-auto"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
