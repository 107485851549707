import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
// global scss for globally styles and fonts
import "./_fonts.scss";
import "./_global.scss";

import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";

import { uniqueId } from "lodash";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
import SendNotificationScreen from "./Pages/SendNotification/SendNotificationScreen.js";
import Setting from "./Pages/Setting";
import UserManagement from "./Pages/UserManagement";
import CardTemplateManagement from "./Pages/CardTemplateManagement/CardTemplateManagement.js";

function App() {
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.ACCOUNT}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,

      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.DASHBOARD}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.CARDTEMPLATE}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={1}>
              <CardTemplateManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.USER_MANAGEMENT}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <UserManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.SEND_NOTIFICATION}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={3}>
              <SendNotificationScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={WEB_ROUTES.SETTING}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <Setting />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={"*"} element={<Navigate to={WEB_ROUTES.ACCOUNT} />} />
      </Routes>
    </Router>
  );
}

export default App;
