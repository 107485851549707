// export const BASE_URL_V1 = "https://api.primetime-tournaments.com/" // todo:
export const BASE_URL_V2 = "https://dummyjson.com"

export const WEB_ROUTES = {
    ACCOUNT: '/account',
    DASHBOARD: '/dashboard',
    USER_MANAGEMENT: '/user-management',
    SEND_NOTIFICATION: '/send-notification',
    SETTING: '/settings',
    CARDTEMPLATE:"card-template-management"
}

export const LOCAL_STORAGE = {
    setUser: 'PTTUSER',
    setToken: 'PTTTOKEN',
}


export const BREAKPOINTS = {
    MOBILE: 991
}
export const apiUrl = {
    auth: 'auth',
    api: 'api',
    role: 'admin',
    user: 'user',
    login: 'login',
    forgotPass: 'forgot-password',
    changePass: 'change-password',

    // tournament
    tournament: 'tournament',
    livetournament: 'tournament/live',
    scheduletournament: 'tournament/schedule',
    ageGroup: 'age-group',
    refund: 'refund',
    startmatch: 'match/start',
    endmatch: 'match/end',
}


// DATA -> 
// DUMMY 
export const sportsStateList = [
    { value: 1, label: 'State 1' }, { value: 2, label: 'State 2' }, { value: 3, label: 'State 3' }];


export const sportsTypeList = [
    { value: 'Baseball', label: 'Baseball' }];


export const formateTTime = (dateStr) => {
    const date = new Date(dateStr);
    const options = { hour: 'numeric', minute: '2-digit', hour12: true };
    const timeFormatted = date.toLocaleString('en-US', options);
    return timeFormatted
}

export const formatedDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: 'numeric',
        // minute: '2-digit',
        // hour12: true
    };
    const dateFormatted = date.toLocaleString('en-US', options);
    return dateFormatted;
}