import { baseApi } from "./baseApi";
import { getToken } from "../../Data/services/localStorageService";

export const notificationApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    sendNotification: builder.mutation({
      query: (user) => {
        return {
          url: "/admin/send-notification",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
  }),
});

export const { useSendNotificationMutation } = notificationApi;
