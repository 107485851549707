import React, { useState } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/sendNotificationScreen.module.scss";
import { useSendNotificationMutation } from "../../Data/services/notificationApi";
import Toast from "../../Hooks/useToast";
import _ from "lodash";
import ButtonLoading from "../../Components/Loaders/ButtonLoading";

const SendNotificationScreen = ({ setPage }) => {
  const [sendNotification, { isLoading }] = useSendNotificationMutation();

  const [title, setTitle] = useState("");

  const [notification, setNotification] = useState("");

  const handleSendNotification = async () => {
    try {
      if (!title || !notification) {
        Toast("Please fill all field", "error");
        return;
      }

      const body = {
        title,
        notification,
      };

      const response = await sendNotification(body);
      if (response?.data?.code == 200) {
        const { message } = response?.data;
        Toast(message, "success");
        setTitle("");
        setNotification("");
      } else if (!_.isEmpty(response.error)) {
        const { message } = response?.error?.data;
        Toast(message, "error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Send Notification"} classes="text-black" />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn}`}
              text={isLoading ? <ButtonLoading /> : "Send"}
              onClick={handleSendNotification}
            />
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <InputGroup className="mb-3">
          <Input
            placeholder="Title"
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <Input
            placeholder="Notification"
            type="textarea"
            as="textarea"
            rows={5}
            value={notification}
            onChange={(e) => {
              setNotification(e.target.value);
            }}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default SendNotificationScreen;
