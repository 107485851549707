import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { useUpdateUserMutation } from "../../Data/services/userApi";
import Toast from "../../Hooks/useToast";
import ButtonLoading from "../Loaders/ButtonLoading";

const EditUserModal = ({ handleEditModalClose, data, refetch }) => {
  const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();

  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    { label: "Active", value: "active" },
    { label: "Block", value: "block" },
  ];

  useEffect(() => {
    if (!_.isEmpty(data)) {
      let status = data?.status ? "active" : "block";
      setSelectedStatus(status);
    }
  }, [data]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleSubmit = async () => {
    try {
      const record = {
        Active: 1,
        Block: 0,
        active: 1,
        block: 0,
      };

      const body = {
        status: record[selectedStatus],
        slug: data.slug,
      };

      await updateUser(body);
      Toast("User update successfully", "success");
      handleEditModalClose();
      refetch();
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 text-black">
              {data ? "Edit User" : "Add User"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Row>
              <Col md={12}>
                <p className="m-0">Status</p>
                <SelectFieldComponent
                  firstOption={
                    _.isEmpty(selectedStatus) ? "Select Status" : selectedStatus
                  }
                  optionsList={statusList}
                  onSelectChange={handleStatusChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={
                    updateLoading ? <ButtonLoading /> : data ? "Update" : "Save"
                  }
                  onClick={handleSubmit}
                  disabled={updateLoading}
                />
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;
